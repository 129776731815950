import React, { useState }  from 'react'
import { graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Modal } from 'react-overlays'


const Shop = ({ data }) => {
    const [thumbindex, setThumbindex] = useState(0);
    const [open, setOpen] = useState(false);
    const item = data.shop;
    const immagini = item.immagini || [item.copertina];
    const immagineHero = {fluid: immagini[0].large};
  
    const renderBackdrop = (props) => {
     return <div {...props} className="modal-bg" />;
    }

    return (
        <Layout>
            <SEO title={item.titolo} immagineHero={immagineHero}/>
            <div className="section">
                <div className="shopItem">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <div className="shopItem__zoom" onClick={() => {setOpen(true)}}>    
                                <Img  className="shopItem__copertina" loading="eager" fluid={immagini[thumbindex].small} />
                            </div>
                            <div className="shopItem__thumbs">
                                {immagini.length > 1 &&
                                    immagini.map((foto,index) => {
                                        return (
                                        <div  key={index} onClick={() => setThumbindex(index)} className={`shopItem__thumbwrapper ${index===thumbindex?'active':''}`}>
                                            <Img className="shopItem__thumb" fluid={foto.small} />
                                        </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-7">
                            <div className="text-center">
                                <h1 className="pagetitle">{item.titolo}</h1>
                                <div>{item.didascalia}</div>
                            </div>
                            <div className="section__text"  dangerouslySetInnerHTML={{__html: item.testo?.childMarkdownRemark.html}} />
                            <div className="shopItem__cta">
                                <div className="row">
                                    <div className="col-6 text-let">
                                        <Link to="/shop" className="btn-action">Torna allo shop</Link>
                                    </div>
                                    <div className="col-6 text-right">
                                        <Link to="/contatti" className="btn-action">Scrivimi</Link>
                                    </div>
                                </div>        
                            </div>
                        </div>
                        
                    </div>
                </div>
                                
                <Modal
                    onHide={() => {setOpen(false)}}
                    aria-labelledby="modal-label"
                    show={open}
                    className="modal-box modal-box--shop"
                    renderBackdrop={renderBackdrop}
                    >
                    <div style={{"max-height": "100vh", "overflow":"auto"}}>
                        <Img fluid={immagini[thumbindex].large} />
                    </div>
                </Modal>
                
            </div>
        </Layout>
    )
}

export const query = graphql`
    query ShopItemQuery($contentful_id: String!) {
        shop: contentfulShop(contentful_id: {eq: $contentful_id}){
            titolo
            didascalia
            prezzo
            linkEtsy
            testo {
                childMarkdownRemark {
                    html
                }
            }
            copertina {
                small: fluid(maxWidth: 600) {
                    ...GatsbyContentfulFluid_withWebp
                }
                large:  fluid(maxWidth: 1240) {
                    ...GatsbyContentfulFluid_withWebp
                }
            }
            immagini {
                small: fluid(maxWidth: 600) {
                    ...GatsbyContentfulFluid_withWebp
                }
                large:  fluid(maxWidth: 1240) {
                    ...GatsbyContentfulFluid_withWebp
                }
            }
        }  
    }
`;

export default Shop;
